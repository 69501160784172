import axios from 'axios'

const instance = axios.create({

 baseURL: 'https://hepos-incident-backend.geotech.gr/'
 //baseURL: 'http://hepos-incident-backend.geotech.gr:8075/'
 //baseURL: 'http://localhost:8000/'
})

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance
