import Vue from 'vue'
import  VueRouter from 'vue-router';
//import Home from '../views/Home.vue'
//import store from '../store/index'
Vue.use(VueRouter)
//import axios from 'axios'
import axios_django from "../axios-auth";

const routes = [
  
  {
    path: '/',
    name: '',
   // redirect: '/dashboard',

    //component: () => import( '../components/Dashboard.vue'),
    component: () => import( '../components/SigninT.vue'),
    
  },
  
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/DashboardT.vue'),
    redirect: '/dashboard/demo',
    children: [
        {
            path: 'demo',
            name: 'Demo',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( '../components/DemoT.vue'),
            beforeEnter (to, from, next) {
              
                //store.dispatch('pageName',{name:'Entomological Data'})
                //next()
              testAuth(next)
             
            }
          },
     
    ]
    
  },
 
]


async function testAuth(next){
  
  
    var token=localStorage.getItem('token')
    console.log(token)
     axios_django.post('verifytoken/',{ token: token })
      .then(()=>{

      var user_data = localStorage.getItem('user')
       console.log(user_data)
      //if(user_data){
      axios_django.get('user',{ headers: { Authorization: 'Bearer ' + token }}).then(function(response){
          localStorage.setItem("user", JSON.stringify(response.data));
          next()
      })
       //}
       
      
      }
        //console.log(response)
      )
       .catch(err => {
       
        next( '/' )
        //next( )
        console.log(err)
     })
     

}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
