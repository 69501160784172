<template>
  <div id="app">
    
    <router-view/>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    
  },
}
</script>

<style>

</style>
